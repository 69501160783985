body {
  margin-left: 10px;
}

.frontImages {
  display: block;
  height: 300px;
  width: 300px;
  margin-top: 20px;
}


